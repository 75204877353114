// Preloader.js

import React from 'react';
import './Preloader.css'; // Import your preloader styling
import { HashLoader } from 'react-spinners';
import { useState, useEffect } from 'react';

const Preloader = () => {

  const [loading, setLoading] = useState(false);

  
  useEffect(() => {

    setLoading(true);

    setTimeout(() => {
      setLoading(false);

    }, 2000);
  }, []);




  return (






    <div className="preloader-container" id='' style={{background:"",width:""  }}>
      {/* <img src=".\images\loader.gif" alt="loading" />
    */}
   
      <HashLoader
               color={"#033056"}
              loading={loading}
               size={150}
               aria-label="Loading Spinner"
               data-testid="loader"
               style={{
                 position:"fixed",
                  // top:"50%",
                  // left:"50%",
                width:"100%",
                height:"100vh",
                
                 background:"white",
                 display:"block",
                 scrollbarWidth:"0%",
                 
                 
               }}
             /> 
   
   
    </div>





  );
};

export default Preloader;
