import React from "react";

const NotFound = () => {
  return <div>
    <p style={{ fontSize: "100px", fontWeight: "bold" }}>404 page not found</p>
    <p>We are sorry but the page you are looking for does not exist.</p>
  </div>
};

export default NotFound;
