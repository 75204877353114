import logo from './logo.svg';
import './App.css';
import Body from './body/body';
import HomePage from './Homes';
import AboutUs from './components/About';
import ITServices from './ItServises';
import Career from './components/Career';
import ContactUs from './components/Contactus';
import Footer from './footer/footer';
import Header from './components/Header';
import { Routes, Route } from "react-router-dom";
import Blog from './components/blog/Blog'
import Product from './components/Product/Product';
import Recruitment from './recruitment/recruitment/Recruitment';
import NotFound from './NotFound';
import { useEffect, useState } from 'react';
// import aos from 'aos';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ClipLoader from "react-spinners/ClipLoader";
import { HashLoader } from 'react-spinners';
import ReactDOM from 'react-dom';



// import { useState } from 'react';
import Preloader from './components/loader/Preloader';
// import YourPageComponent from './YourPageComponent'; // Import your page component

function App() {

  useEffect(() => {
    Aos.init({ 
      duration: 1000,
       disable: 'true'

     }); // Adjust duration or other options as needed
  }, []); 


  // useEffect(() => {
  
  //   Aos.init({
  //     disable: true,
  //   });
  
  // }, []);



  // const [loading, setLoading] = useState(false);

  // // Function to simulate asynchronous loading (replace with your actual loading logic)
  // const simulateLoading = () => {


  //   document.documentElement.style.overflow = 'hidden';

  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);


  //     document.documentElement.style.overflow = 'visible';

  //   }, 2000); 

  //   // Simulating a 2-second loading time
  // };


  // const [loading, setLoading] = useState(false);

  // useEffect(() => {

  //   setLoading(true);

  //   setTimeout(() => {
  //     setLoading(false);

  //   }, 2000);
  // }, []);




  return (
    <div className="App">
      {
        // loading ?



        //   <HashLoader
        //     color={"#033056"}
        //     loading={loading}
        //     size={150}
        //     aria-label="Loading Spinner"
        //     data-testid="loader"
        //     style={{
        //       position: "absolute",
        //       top: "50%",
        //       left: "50%"
        //     }}
        //   />

        //   :


        <>
          <Header />






          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/itservices" element={<ITServices />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/product" element={<Product />} />
            <Route path="/recruitment" element={<Recruitment />} />
            <Route path="/product" element={<Product />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />


        </>

      }
    </div>
  );
}

export default App;
