import React from 'react'
import Header from './Header'
import './css/Contactus.css'
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';


import bit_contact_call_icon1 from './images/bit-contact-form-call-img1.png';
import bit_contact_email_icon1 from './images/bit-contact-form-email-img1.png';
import bit_contact_website_icon1 from './images/bit-contact-form-website-img1.png';

import bit_contact_formsec_img1 from "../components/images/bit-contact-us-form-left-img1.png";

import bit_contact_website_form_left_img1 from './images/bit-contact-us-form-left-img1.png';



function ContactUs() {
    useEffect(() => {
        document.title = 'Contact Us';

        // Function to handle phone click
        const handlePhoneClick = (e) => {
            const phone = e.target.getAttribute('data-phone');
            if (phone) {
                window.location.href = `tel:${phone}`;
            }
        };

        // Add event listeners
        const clickablePhones = document.querySelectorAll('.clickable-phone');
        clickablePhones.forEach(element => {
            element.addEventListener('click', handlePhoneClick);
        });

        // Clean up event listeners on component unmount
        return () => {
            clickablePhones.forEach(element => {
                element.removeEventListener('click', handlePhoneClick);
            });
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        last: '',
        email: '',
        company: '',
        subject: '',
        phone: '',
        city: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const validationMessages = {
        name: 'Please enter your first name.',
        last: 'Please enter your last name.',
        email: 'Please enter a valid email address.',
        company: 'Please enter your company name.',
        subject: 'Please select a subject.',
        phone: 'Please enter a valid phone number.',
        city: 'Please enter your city.',
        message: 'Please enter your message.',
    };

    const validationPatterns = {
        name: /^[a-zA-Z\s]+$/,
        last: /^[a-zA-Z\s]+$/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        company: /.+/,
        subject: /.+/,
        phone: /^\d{10,15}$/,
        city: /^[a-zA-Z\s]+$/,
        message: /.+/,
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleValidation = () => {
        const newErrors = {};
        let isValid = true;

        Object.keys(validationMessages).forEach((key) => {
            if (!validationPatterns[key].test(formData[key].trim())) {
                newErrors[key] = validationMessages[key];
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!handleValidation()) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill in all required fields correctly.',
            });
            return;
        }

        Swal.fire({
            icon: 'success',
            title: 'Thanks for enquiring! We will contact you soon.',
            showConfirmButton: false,
            timer: 2000,
        });

        setFormData({
            name: '',
            last: '',
            email: '',
            company: '',
            subject: '',
            phone: '',
            city: '',
            message: '',
        });
        setErrors({});
    };



    return (
        <div>
            {/*  <Header style={{ color: "#03356" }} /> */}
            <section className='sectioncn1  w-100 d-flex align-items-center'>
                <div class="container">
                    <div className=' text-start '>
                        <p className='sectioncn1-p1 col-md-7'>Contact us </p>
                        <p className='sectioncn1-p2 col-md-7'>How can we help you?</p>
                    </div>
                </div>
            </section>

            <section class=" vh-75 sectioncn2">
                <h1 className='text-center col-md-12 pt-5'>Contact Now</h1>
                <div className='d-flex align-items-center'>
                    <div class="container  ">

                        <div class="row  mb-4  text-start m-0 p-0 w-100">

                            <div class="col-lg-6 col-md-12 w-100 p-1">
                                <div class="card" data-aos="">
                                    <div class="card-body ps-5 bit-contactus-cont-now-crd-body">
                                        <h5 class="text-left bit-contactus-cont-now-crd-body-title1">Sales Department</h5>

                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/pho.png' className='mr-4' id='pho-contactus' />
                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>

                                                <h6 class=" mb-2 text-body-secondary text-center d-inline">+91 9146050013</h6>
                                            </div>


                                        </div>



                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/mail.png' className='mr-4' id='mail-contactus' />
                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>

                                                <a class="card-link text-primary w-auto d-inline  text-break">sales@brightlinkinfotechnologies.com</a>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 w-100 p-1">
                                <div class="card  " data-aos="">
                                    <div class="card-body ps-5 bit-contactus-cont-now-crd-body">
                                        <h5 class=" text-left bit-contactus-cont-now-crd-body-title1">HR Department</h5>


                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/pho.png' className='mr-4' id='pho-contactus' />
                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>
                                                <h6 class=" mb-2 text-body-secondary  d-inline">+91 9146050013</h6>
                                            </div>


                                        </div>


                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/mail.png' className='mr-4' id='mail-contactus' />
                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>
                                                <a class="card-link text-primary w-auto d-inline  text-break">hr@brightlinkinfotechnologies.com</a>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4 text-start m-0 p-0 w-100">
                            <div class="col-lg-6 col-md-12 w-100 p-1">
                                <div class="card " data-aos="">
                                    <div class="card-body ps-5 bit-contactus-cont-now-crd-body">
                                        <h5 class="card-title bit-contactus-cont-now-crd-body-title1">Support department</h5>


                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/pho.png' className='mr-4' id='pho-contactus' />

                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>
                                                <h6 class=" mb-2 text-body-secondary text-center d-inline">+91 9146050013</h6>
                                            </div>


                                        </div>


                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/mail.png' className='mr-4' id='mail-contactus' />


                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>
                                                <a class="card-link text-primary w-auto d-inline  text-break">support@brightlinkinfotechnologies.com</a>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 w-100 p-1">
                                <div class="card" data-aos="">
                                    <div class="card-body ps-5 bit-contactus-cont-now-crd-body">
                                        <h5 class="card-title bit-contactus-cont-now-crd-body-title1">UK SALES AND SUPPORT</h5>


                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/pho.png' className='mr-4' id='pho-contactus' />


                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>
                                                <h6 class=" mb-2 text-body-secondary text-center d-inline">+44 7448 674419</h6>
                                            </div>


                                        </div>

                                        <div className='w-100 mb-2 bit-contactus-cont-now-crd-body-row1'>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col1'>
                                                <img src='../images/mail.png' className='mr-4' id='mail-contactus' />


                                            </div>

                                            <div className='bit-contactus-cont-now-crd-body-row1-col2'>

                                                <a class="card-link text-primary w-auto d-inline  text-break">uk.sales@brightlinkinfotechnologies.com</a>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* <section className='bit-contact-our-location-sec'>

                <p className='bit-contact-our-location-sec-p1'>OUR LOCATION</p>

                <div className='row m-0 p-0 w-100 bit-contact-our-location-sec-row1'>


                    <div className='col-lg-6 bit-contact-our-location-sec-col1'>
                        <p className='bit-contact-our-location-sec-col1-p1'>Indian address</p>
                        <p className='bit-contact-our-location-sec-col1-p2'>1st Floor, Shalini Corner Bulding, Office No 11, Pune, Maharashtra 411052</p>
                        <p className='bit-contact-our-location-sec-col1-p3'>Indian Number</p>
                        <p className='bit-contact-our-location-sec-col1-p4'>+91 8149055001</p>
                    </div>
                    <div className='col-lg-6 bit-contact-our-location-sec-row1-col2'>
                        <p className='bit-contact-our-location-sec-col2-p1'>UK Address</p>
                        <p className='bit-contact-our-location-sec-col2-p2'>95 King Alfred Street, Derby, United Kingdom, DE22 3QL</p>
                        <p className='bit-contact-our-location-sec-col2-p3'>UK Number</p>
                        <p className='bit-contact-our-location-sec-col2-p4'>+44 7448 674419</p>
                    </div>
                </div>
            </section> */}

            {/* 
            <section className='sectioncont4-main'>
                <h1>Our Location</h1>
            </section> */}

            <section className='w-100'>
                <div className='W-100 position-relative'>
                    <img src='../images/map1.png' className='w-100 h-100 img-fluid'></img>
                    {/* <img src='../images/mpinred1.gif' className="img-fluid position-absolute z-2" id='mp2' /> */}

                    <div class="skill position-absolute">


                        <div class="li ">

                            <img src='../images/mpinred1.gif' className="img-fluid z-2" id='mp2' />
                            <div class="content container-fluid">
                                <div className='sub-content'>
                                    <p class="content-p1 fw-bold">India</p>
                                    <p class="content-p2">Pune, Maharashtra</p>
                                    {/* <p> Indian Number :   +91 8149055001</p> */}


                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="skill1 position-absolute">


                        <div class="li ">

                            <img src='../images/mpinred1.gif' className="img-fluid z-2" id='mp2' />
                            <div class="content container-fluid">
                                <div className='sub-content'>
                                    <p class="content-p1 fw-bold">United Kingdom</p>
                                    <p class="content-p2"> Derby</p>
                                    {/* <p> UK Number : +447448674419</p> */}


                                </div>

                            </div>
                        </div>

                    </div>

                    {/* <img src='../images/mpinred1.gif' className="img-fluid position-absolute z-2" id='mp1' />




                    <img src='../images/mpinred1.gif' className="img-fluid position-absolute z-2" id='mp2' /> */}

                </div>
            </section>
            {/* <section className='sectioncn4 w-100 d-flex align-items-center'>


                <div class="skill">

                    <div className='location1'>

                        <div class="li">
                            <img src='../images/mpinred1.gif'></img>
                            <div class="content">
                                <div className='sub-content'>
                                    <h6 class="">Indian address</h6>
                                    <p class="">1st Floor, Shalini Corner Bulding, Office

                                        No 11,Pune, Maharashtra 411052</p>
                                    <p> Indian Number :   +91 8149055001</p>


                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='location2'>

                        <div class="li">
                            <img src='../images/mpinred1.gif'></img>
                            <div class="content">
                                <div>
                                    <h6 class="">UK Addres</h6>
                                    <p class="">95 King Alfred Street, Derby, United Kingdom, DE22 3QL</p>
                                    <p class="">UK Number : +447448674419</p>

                                </div>


                            </div>
                        </div>
                    </div>






                </div>




            </section> */}

            <div className='bit-contact-us-form-main'>

                <div className='bit-contact-us-form-main-sub1'>



                    <div className='row m-0 p-0 w-100 bit-contact-us-form-main-row1'>

                        <div className='col-lg-6 bit-contact-us-form-main-row1-col1'>
                            <p className='bit-contact-us-form-main-row1-col1-p1'>Get In <span className='bit-contact-us-form-main-row1-col1-p1-span'> Touch</span></p>
                            <p className='bit-contact-us-form-main-row1-col1-p2'>Have a question or need assistance? Reach out to us via email, phone, or the contact form below. </p>
                            <p className='bit-contact-us-form-main-row1-col1-p3'>We're eager to assist you.</p>




                            <div className="Jarag-row1-col1-subcol">

                                <div className="Jarag-row1-col1-subcol-div1">
                                    <div className="Jarag-row1-col1-subcol-div1-img1">
                                        <img src={bit_contact_call_icon1} alt="Phone Number Symbol" className='Jarag-row1-col1-subcol-div1-img1-img' />
                                    </div>
                                    <div className="Jarag-row1-col1-subcol-div1-para1">
                                        <h4>India Phone Number</h4>
                                        <p className='clickable-phone' data-phone="+91 8149055001">+91 8149055001</p>
                                    </div>
                                </div>



                                <div className="Jarag-row1-col1-subcol-div1">
                                    <div className="Jarag-row1-col1-subcol-div1-img1">
                                        <img src={bit_contact_call_icon1} alt="Phone Number Symbol" className='Jarag-row1-col1-subcol-div1-img1-img' />
                                    </div>
                                    <div className="Jarag-row1-col1-subcol-div1-para1">
                                        <h4>UK Phone Number</h4>
                                        <p className='clickable-phone' data-phone="+44 7448 674419">+44 7448 674419</p>
                                    </div>
                                </div>





                                <div className="Jarag-row1-col1-subcol-div1">
                                    <div className="Jarag-row1-col1-subcol-div1-img1">
                                        <img src={bit_contact_email_icon1} alt="Email Address Symbol" className='Jarag-row1-col1-subcol-div1-img1-img' />
                                    </div>
                                    <a className="Jarag-row1-col1-subcol-div1-para1" href="mailto:info@brightlinkinfotechnologies.com">
                                        <h4>Email</h4>
                                        <p>info@brightlinkinfotechnologies.com</p>
                                    </a>
                                </div>

                                <div className="Jarag-row1-col1-subcol-div1">
                                    <div className="Jarag-row1-col1-subcol-div1-img1">
                                        <img src={bit_contact_website_icon1} alt="Email Address Symbol" className='Jarag-row1-col1-subcol-div1-img1-img' />
                                    </div>
                                    <a className="Jarag-row1-col1-subcol-div1-para1" href='https://www.brightlinkinfotechnologies.com/'>
                                        <h4>Website</h4>
                                        <p className='arag-row1-col1-subcol-div1-para1-website-p1'>https://www.brightlinkinfotechnologies.com</p>
                                    </a>
                                </div>

                                <img src={bit_contact_website_form_left_img1} alt="Email Address Symbol" className='bit-contactus-form-left-img1' />

                            </div>
                        </div>


                        <div className='col-lg-6 bit-contact-us-form-main-row1-col2'>

                            <p className='bit-contact-us-form-main-row1-col2-title1'>Contact Us</p>

                            <form id="contactForm" className="row" onSubmit={handleSubmit}>
                                <div className="col-lg-12 col-md-12 col-sm-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="name" className="bit-contact-us-form-main-row1-col2-form-lable1">First Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Brian"
                                    />
                                    {errors.name && <div id="nameValidation" className="invalid-feedback">{errors.name}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="last" className="bit-contact-us-form-main-row1-col2-form-lable1">Last Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.last ? 'is-invalid' : ''}`}
                                        id="last"
                                        name="last"
                                        value={formData.last}
                                        onChange={handleChange}
                                        placeholder="Smith"
                                    />
                                    {errors.last && <div id="lastValidation" className="invalid-feedback">{errors.last}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="email" className="bit-contact-us-form-main-row1-col2-form-lable1">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="
support@brightlinkinfotechnologies.com"
                                    />
                                    {errors.email && <div id="emailValidation" className="invalid-feedback">{errors.email}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="company" className="bit-contact-us-form-main-row1-col2-form-lable1">Company</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.company ? 'is-invalid' : ''}`}
                                        id="company"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        placeholder="BrightLink Info Technologies Pvt Ltd"
                                    />
                                    {errors.company && <div id="companyValidation" className="invalid-feedback">{errors.company}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="subject" className="bit-contact-us-form-main-row1-col2-form-lable1">Subject</label>
                                    <select
                                        className={`form-select ${errors.subject ? 'is-invalid' : ''}`}
                                        id="subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled hidden>Subject</option>
                                        <option value="It Services">IT Services</option>
                                        <option value="Recruitment Service">Recruitment Service</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Training">Training</option>
                                    </select>
                                    {errors.subject && <div id="subjectValidation" className="invalid-feedback">{errors.subject}</div>}
                                </div>

                                <div className="col-lg-6 col-md-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="phone" className="bit-contact-us-form-main-row1-col2-form-lable1">Phone</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="+91 8149055001"
                                    />
                                    {errors.phone && <div id="phoneValidation" className="invalid-feedback">{errors.phone}</div>}
                                </div>

                                <div className="col-lg-6 col-md-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="city" className="bit-contact-us-form-main-row1-col2-form-lable1">City</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        placeholder="Pune"
                                    />
                                    {errors.city && <div id="cityValidation" className="invalid-feedback">{errors.city}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-divs-sets">
                                    <label htmlFor="message" className="bit-contact-us-form-main-row1-col2-form-lable1">Your Message</label>
                                    <textarea
                                        className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Type your message here..."
                                    ></textarea>
                                    {errors.message && <div id="messageValidation" className="invalid-feedback">{errors.message}</div>}
                                </div>

                                <div className="col-12 bit-contact-us-form-main-row1-col2-form-BTN">
                                    <button type="submit" className="bit-contact-us-form-main-contact-btn">Submit</button>
                                </div>
                                <div id="status"></div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


            {/* 
            <div className="feedback">

                <div className='feedback-leftpart'>

                    <img src='../images/feed-bg.png'></img>

                </div>

                <div className='feedback-rightpart'>

                    <form >
                        <div className='feedback-main'>

                            <div className='feedback-main-1'>
                                <p>Contact Us</p>
                                <input type="name" name='name' value={email.name} onChange={handleInputChange} placeholder='Name' className="form-control" required />
                                <input type="email" name='email' value={email.email} onChange={handleInputChange} placeholder='Email' className="form-control" required />
                                <input type="number" name='number' value={email.number} onChange={handleInputChange} placeholder='Phone' className="form-control" required />

                                <select
                                    className="form-select"
                                    name="subject"
                                    id="feedback-main-1-selectdrop"
                                    aria-label="Default select example"
                                    value={email.subject}
                                    onChange={handleInputChange}
                                >

                                    <option value="" disabled hidden>
                                        Subject
                                    </option>
                                    <option value="It Services">It Services</option>
                                    <option value="Recruitment Service">Recruitment Service</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Training">Training</option>
                                </select>

                            </div>

                        </div>

                        <div className='feedback-main-3'>
                            <textarea
                                name='message'
                                value={email.message}
                                onChange={handleInputChange}
                                placeholder="Your message"
                                className="form-control"
                                rows={5}
                                required
                            ></textarea>
                        </div>


                        <div className='feedback-main-5'>
                            <span>{alert}</span>
                            <button type="submit" onClick={handleSubmit}>
                                <div className="svg-wrapper-1">
                                    <div className="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <span>Send</span>
                            </button>
                        </div>
                    </form>

                    <ToastContainer />
                </div>
            </div> */}




        </div>
    )
}

export default ContactUs;
