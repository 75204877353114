
import rect_21 from "./images/home/Rectangle_21.png";
import gp30 from "./images/home/Group_30.png";
import gp31 from "./images/home/Group_31.png";
import gp32 from "./images/home/Group_32.png";
import al from "./icons/home/arrow-left.png";
import ar from "./icons/home/arrow-right.png";
import ve from "./icons/home/Vector.png";
import rec39 from "./images/home/Rectangle 39.png";
import rec40 from "./images/home/Rectangle 40.png";
import rec41 from "./images/home/Rectangle 41.png";
import Header from "./components/Header.js";
import { useEffect, useState } from "react";
import Preloader from "./components/loader/Preloader.js";
// import Aos from "aos";
import BITown_hm_sec3_row1_col1_img from "./images/home/BIT-own-hm-sec3-row1-col1-img.png";
import BITown_hm_sec3_row1_col2_img from "./images/home/BIT-own-hm-sec3-row1-col2-img.png";
import BITown_hm_sec3_row2_col1_img from "./images/home/BIT-own-hm-sec3-row2-col1-img.png";
import BITown_hm_sec3_row3_col1_img from "./images/home/BIT-own-hm-sec3-row3-col1-img.png";
import BITown_hm_sec3_row4_col1_img from "./images/home/BIT-own-hm-sec3-row4-col1-img.png";
import BITown_hm_sec3_row5_col1_img from "./images/home/BIT-own-hm-sec3-row5-col1-img.png";
import BITown_hm_sec3_row6_col1_img from "./images/home/BIT-own-hm-sec3-row6-col1-img.png";
import BITown_hm_sec3_row7_col1_img from "./images/home/BIT-own-hm-sec3-row7-col1-img.png";
import BITown_hm_sec4_BLOG from "./images/home/BIT-BLOG input1-send-bt-img.png";

import BITown_hm_AND_blg_Combo_sec4 from "./images/home/BIT-own-hm-sechome6-and-sec3-blg-com.png";


import BITown_hm_sec4_MVP_left_Img from "./images/home/BIT-hm-sec4-MVP-left-img.png";





// c:\Users\admin\Downloads\BIT-own-hm-sec3-row2-col1-img.png c:\Users\admin\Downloads\BIT-own-hm-sec3-row7-col1-img.png c:\Users\admin\Downloads\BIT-own-hm-sec3-row6-col1-img.png c:\Users\admin\Downloads\BIT-own-hm-sec3-row5-col1-img.png c:\Users\admin\Downloads\BIT-own-hm-sec3-row4-col1-img.png c:\Users\admin\Downloads\BIT-own-hm-sec3-row3-col1-img.png

import BITown_hm_sec3_row1_col_reverce from "./images/home/BIT-own-hm-sec3-row1-col1-reverce-img.png";

// const Homes = () => {
const Homes = ({ loading }) => {

    document.title = 'home'

    // useEffect(() => {
    //     Aos.init();
    // })
    



    const [showMessage1, setShowMessage1] = useState(false);
    const [showMessage2, setShowMessage2] = useState(false);
    const [showMessage3, setShowMessage3] = useState(false);
    const [showMessage4, setShowMessage4] = useState(false);
    const [showMessage5, setShowMessage5] = useState(false);

    const handleClick = (id) => {

        switch (id) {
            case 1:
                setShowMessage1(!showMessage1);
                break;

            case 2:
                setShowMessage2(!showMessage2);
                break;
            case 3:
                setShowMessage3(!showMessage3);
                break;

            // case 4:
            //     setShowMessage4(!showMessage4);
            //     break;

            // case 5:
            //     setShowMessage5(!showMessage5);
            //     break;
            default:
        }
        // Toggle showMessage state
    };


    return (
        <div>
            {loading ? (

                <Preloader style={{ display: 'block' }} />
                // </div>
            ) : (
                <>


                    {/* --------------------------------section 1---------------------------- */}



                    <div className="sechome1 ">
                        <div className='sec1-1'>
                            <div className="subsec1  ">

                                <div className="d-flex justify-content-center ">
                                    <div className="container  ">
                                        <div class="row d-flex justify-content-center ">
                                            <div class="sideline col-2" style={{ width: "25%" }} >
                                                <hr className="side-line-home text-light" style={{ fontWeight: "bold", opacity: "100%" }}></hr>
                                            </div>

                                            <div class="" style={{ width: "auto" }}>
                                                <p className="sec1hm-subhead1 fw-25 text-light">Connecting Talent with Technology </p>
                                            </div>

                                            <div class="sideline col-2" style={{ width: "25%" }}>

                                                <hr className="side-line-home text-light " style={{ fontWeight: "bold", opacity: "100%" }}></hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="sec1hm-headin1 d-flex justify-content-center"><p className="h1-g ">  IT & TECHNOLOGY PARTNER</p></div>


                                <div className="below500-block-part1">
                                    <p className="sec1hm-headin1-p text-center text-light" style={{ fontSize: "large" }}>Your Comprehensive Destination for IT Services, Talent Sourcing and Professional Growth<br></br> Opportunities Worldwide</p>
                                    <div class="d-flex justify-content-center">
                                        <a href="/contact" className="btn nav-link btn-primary bg-transparent border-white">Get In Touch</a>
                                    </div>
                                </div>
                            </div >



                            <div className="below500-block-part2">

                                <div class="sec1hmdevelopment">
                                    <div class="">
                                        <figure>
                                            <img src="/img/mbDev.png" className="img1" ></img>
                                            <figcaption className="figcap">Mobile development</figcaption>
                                        </figure>
                                    </div>

                                    <div class="">
                                        <figure>
                                            <img src="/img/web.png" className="img2"></img>
                                            <figcaption className="figcap"  >Web development</figcaption>
                                        </figure>

                                    </div>

                                    <div class="">
                                        <figure>
                                            <img src="/img/gr.png" className="img3" ></img>
                                            <figcaption className="figcap">Graphics & UI/UX Design</figcaption>
                                        </figure>

                                    </div>

                                    <div class="" >
                                        <figure>
                                            <img src="/img/it-rec.png" className="img4 "  ></img>
                                            <figcaption className="figcap">MVP Development</figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>

                    <div className="sectionhomefor-meadia-skin">
                        <div className="sectionhomefor-meadia">





                            <div className="">
                                <p className="sec1hm-headin1-p text-center text-light" id="sec1hm-headin1-p-for550" style={{ fontSize: "large" }}>Your Comprehensive Destination for IT Services, Talent Sourcing, and ProfessionalGrowth<br></br> Opportunities Worldwide</p>
                                <div class="d-flex justify-content-center">
                                    <button href="#" className="btn btn-primary bg-transparent border-white">Keep Recruitment</button>
                                </div>

                                <div class="sec1hmdevelopment">
                                    <div class="">
                                        <figure>
                                            <img src="/img/mbDev.png" className="img1" ></img>
                                            <figcaption className="figcap">Mobile development</figcaption>
                                        </figure>
                                    </div>

                                    <div class="">
                                        <figure>
                                            <img src="/img/web.png" className="img2"></img>
                                            <figcaption className="figcap"  >Web development</figcaption>
                                        </figure>

                                    </div>

                                    <div class="">
                                        <figure>
                                            <img src="/img/gr.png" className="img3" ></img>
                                            <figcaption className="figcap">Graphics & UI/UX Design</figcaption>
                                        </figure>

                                    </div>

                                    <div class="" >
                                        <figure>
                                            <img src="/img/it-rec.png" className="img4 "  ></img>
                                            <figcaption className="figcap">MVP Development</figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* -----------------new second section----------- */}
                    <section className="sechome2">

                        <div className="sechome2-main">

                            <div className="sechome2-part2">
                                <div class="sechome2-part2-content card-body" >
                                    <h5 class="sechome2-part2-head card-title" >Why  to choose us</h5>
                                    <p class="sechome2-part2-p1 card-text"  >Choose BrightLink Info Technologies for unparalleled expertise and innovation in software solutions. Our commitment to cutting-edge technology, Personalized services and a proven track record of delivering exceptional result sets us apart. Experience excellence with our forward-thinking team dedicated to turning your vision into reality.</p>
                                    <a href="/about" class="sechome2-btn1  text-white">Learn More</a>
                                </div>


                            </div>


                            <div className="sechome2-part1">


                                <div class="sechome2-part1-left">

                                    <div class="sechome2-part1-box1 card-body rounded-4 text-start shadow"  data-aos="fade-down" >
                                        <img src="/img/sec2/man.png"></img>
                                        <div >
                                            <h5 class="sechome2-part1-box1-title  card-title" >Expertise</h5>
                                            <p class="sechome2-part1-box1-content card-text" >We have experienced professionals with expertise in various technologies.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="sechome2-part1-box2 card-body rounded-4 text-start "  data-aos="fade-up">

                                        <img src="/img/sec2/scal.png" ></img>

                                        <div className="">
                                            <h5 class="sechome2-part1-box2-title card-title" >Scalability</h5>

                                            <p class="sechome2-part1-box1-content card-text" >We can handle projects of various sizes and can scale resources as needed.</p>
                                        </div>

                                    </div>

                                </div>


                                <div class="sechome2-part1-right">

                                    <div class="sechome2-part1-box3  card-body rounded-4 text-start " data-aos="fade-down">
                                        <img src="/img/sec2/watch.png"></img>
                                        <div >
                                            <h5 class="sechome2-part1-box3-title card-title" >Custom solutions</h5>

                                            <p class="sechome2-part1-box1-content card-text" >We can tailor web solutions to your specific needs and goals.</p>
                                        </div>
                                    </div>


                                    <div class="sechome2-part1-box4 card-body rounded-4 text-start shadow  " data-aos="fade-up">
                                        <img src="/img/sec2/light.png" ></img>
                                        <div>
                                            <h5 class="sechome2-part1-box4-title card-title" >Innovation</h5>

                                            <p class="sechome2-part1-box1-content card-text" >Stay up-to-date with the latest trends and technology around the globe.</p>
                                        </div>
                                    </div>

                                </div>










                            </div>


                        </div>
                    </section>


                    {/* ------------------------------------section 3--------------------------- */}
                    <div class="container-fluid " style={{ background: "#033056", paddingTop: "5%", paddingBottom: "5%" }}>



                        <div className="container">
                            <p className="bit-own-hm-sec3-title1">Our Product Development Process</p>

                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1" data-aos="zoom">

                                <div className="col-md-4">
                                    <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row1_col2_img} alt="" className="bit-own-hm-sec3-row1-img2" />
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1"> Requirement Gathering and Analysis</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Collecting and analyzing customer needs, business requirements, and technical specifications to ensure a clear understanding of the project scope.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 m-0 w-100 bit-own-hm-sec3-row1" data-aos="zoom-in" >

                                <div className="col-md-4">
                                    <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row2_col1_img} alt=""  className="bit-own-hm-sec3-row1-img2"/>
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1"> Planning</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Assessing the technical, financial, and operational feasibility of the project, and creating a detailed project plan with timelines, resources, and milestones.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1" data-aos="zoom-in" >

                                <div className="col-md-4">
                                    <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row3_col1_img} alt="" className="bit-own-hm-sec3-row1-img2" />
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1">Design</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Designing the system architecture and creating detailed design documents that outline the software structure, components, interfaces, and data flow.</p>

                                    </div>
                                </div>
                            </div>



                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1" data-aos="zoom-in" >

                                <div className="col-md-4">
                                    <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row4_col1_img} alt="" className="bit-own-hm-sec3-row1-img2" />
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1">Development</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Writing and compiling the source code based on the design specifications, following coding standards and best practices to ensure quality and maintainability.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1" data-aos="zoom-in" >

                                <div className="col-md-4">
                                    <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row5_col1_img} alt=""  className="bit-own-hm-sec3-row1-img2" />
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1"> Testing</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Conducting various levels of testing, including unit, integration, system, and user acceptance testing, to identify and fix bugs and ensure the software meets quality standards..</p>

                                    </div>
                                </div>
                            </div>

                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1" data-aos="zoom-in" >

                                <div className="col-md-4">
                                <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row7-img1" />
                                <img src={BITown_hm_sec3_row1_col_reverce} alt="" className="bit-own-hm-sec3-row7-img2" />
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row6_col1_img} alt=""  className="bit-own-hm-sec3-row1-img2"/>
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1">Deployment</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Deploying the software to the production environment, configuring necessary settings, and ensuring that it operates as intended in the live environment.</p>

                                    </div>
                                </div>
                            </div>

                            <div className="row w-100 m-0 p-0 bit-own-hm-sec3-row1 bit-own-hm-sec3-last-row"  data-aos="zoom-in"  >

                                <div className="col-md-4">
                                {/* <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row1-img1" /> */}
                                    {/* <img src={BITown_hm_sec3_row1_col1_img} alt="" className="bit-own-hm-sec3-row7-img1" />
                                    <img src={BITown_hm_sec3_row1_col_reverce} alt="" className="bit-own-hm-sec3-row7-img2" /> */}
                                </div>
                                <div className="col-md-3">
                                    <img src={BITown_hm_sec3_row7_col1_img} alt="" className="bit-own-hm-sec3-row1-img2"/>
                                </div>


                                <div className="col-md-5">
                                    <div>
                                        <p className="BIT-own-hm-sec3-row1-p1"> Maintenance & Support</p>
                                        <p className="BIT-own-hm-sec3-row1-p2">Providing ongoing support, monitoring the system for issues, and implementing updates, patches, and enhancements to improve functionality and address any problems.</p>

                                    </div>
                                </div>
                            </div>



                        </div>

                        {/*                       
                        <div class="row align-items-center" >
                           
                            <div className="col-sm " data-aos="fade-down" data-aos-offset="100" style={{ color: "white", background: "transparent", }}>
                                <p className="sectionhome3-title " >Explore Our Proven Hiring Process</p>
                            </div>

                            <div class="col-sm" style={{ minWidth: "65%" }}>
                                <div className="d-flex my-5 py-3 align-items-center">
                                    <div className="d-flex align-items-center mx-4" style={{ width: "30%" }} >
                                        <p class="h1 text-light ml-4 " style={{
                                            paddingRight: "13%"
                                        }}>01</p>
                                        <img src="/img/sec3/ic1.png" id="homesection3-img1" style={{ width: "40%" }}></img>
                                    </div>

                                    <div className="text-start" data-aos="fade-up" style={{ width: "50%" }}>
                                        <h3 class="text-light" style={{ fontWeight: "bold", }}>Identifying Candidates</h3>
                                        <p class="text-light">We source qualified professionals from diverse channel, building a robust candidate pool through job boards, referrals and networking.</p>
                                    </div>
                                </div>
                                <div className="d-flex my-5 py-3 align-items-center" style={{}}>
                                    <div className="d-flex align-items-center mx-4" style={{ width: "30%" }} >
                                        <p class=" h1 text-light ml-4 " style={{ paddingRight: "13%" }}>02</p>
                                        <img src="/img/sec3/ic2.png" id="homesection3-img1" style={{ width: "40%" }}></img>
                                    </div>

                                    <div className="text-start" data-aos="fade-up" style={{ width: "50%" }}>

                                        <h3 class="text-light" style={{ fontWeight: "bold", }}>Shortlisting and Screening</h3>
                                        <p class="text-light">Candidates should carefully assessed based on experience,  skills and fit in depth evaluations including face to face interviews and background checks are conducted.</p>

                                    </div>
                                </div>
                                <div className="d-flex my-5 py-3 align-items-center" style={{}}>
                                    <div className="d-flex align-items-center mx-4" style={{ width: "30%" }} >
                                        <p class=" h1 text-light ml-4 " style={{ paddingRight: "13%" }}>03</p>
                                        <img src="/img/sec3/ic3.png" id="homesection3-img1" style={{ width: "40%" }}></img>
                                    </div>

                                    <div className="text-start" data-aos="fade-up" style={{ width: "50%" }}>
                                        <h3 class="text-light" style={{ fontWeight: "bold", }}>Placement and Ongoing support</h3>
                                        <p class="text-light">Unlike others our commitment doesn’t end with placement. We continuously monitor employee performance and ensuring their top quality services.</p>
                                    </div>
                                </div>

                            </div>



                        </div> */}

                    </div>

                    {/* --------------------------------section 4---------------------------- */}

                    <section className="BIT-hm-sec4-MVP-main">


                        <p className="BIT-hm-sec4-MVP-p1" data-aos="zoom-in" >Minimum Viable Product (MVP)</p>

                        <p className="BIT-hm-sec4-MVP-p2" >Our focus is on MVP product development, ensuring users can easily adopt and benefit from our solutions. We address the core problem of your value proposition, demonstrating clear value to customers.
                        </p>


                        <div className="row w-100 p-0 m-0">

                            <div className="col-lg-6 col-md-12 BIT-hm-sec4-MVP-left-col-img--div">
                                <img src={BITown_hm_sec4_MVP_left_Img} alt="" className="BIT-hm-sec4-MVP-left-col-img" />
                            </div>

                            <div className="col-lg-6 col-md-12">

                                <div>
                                    <div className="card BIT-hm-sec4-MVP-left-col2-card1" data-aos="fade-down" >
                                        <div className="card-body card BIT-hm-sec4-MVP-left-col2-card-bdy1">
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p1">Core Functionality</p>
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p2">Core features that solve the primary problem for users. Non-essential features are excluded to reduce development time and costs.</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="card BIT-hm-sec4-MVP-left-col2-card1" data-aos="fade-down" >
                                        <div className="card-body card BIT-hm-sec4-MVP-left-col2-card-bdy1">
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p1">User Feedback</p>
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p2">Based on user feedback, the product is iteratively improved, adding features and making adjustments as needed.</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="card BIT-hm-sec4-MVP-left-col2-card1" data-aos="fade-down" >
                                        <div className="card-body card BIT-hm-sec4-MVP-left-col2-card-bdy1">
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p1">Quick Time to Market</p>
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p2">By focusing only on the essential features, the MVP can be developed and launched quickly.</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="card BIT-hm-sec4-MVP-left-col2-card1" data-aos="fade-down" >
                                        <div className="card-body card BIT-hm-sec4-MVP-left-col2-card-bdy1">
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p1">Cost Efficiency</p>
                                            <p className="BIT-hm-sec4-MVP-left-col2-card-bdy1-p2">Building only the minimum necessary features lowers initial development costs.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                    {/* <section style={{ backgroundColor: "#F0F9FF" }}>
                        <div class="sectionhome4-title">
                            <div class="" >
                                <p class="sectionhome4-title-p" data-aos="fade-up">Explore Our Recruitment Services</p>
                            </div>
                        </div>


                        <div class="sectionhome4-silde-main d-flex align-items-center py-3" >

                            <div>
                                <img src={rect_21} className="sectionhome4-silde-main-leftimg" />
                            </div>
                            <div class="sectionhome4-silde-main-right">
                                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">

                                        <div class="sectionhome4-silde-main-right-img1 carousel-item active car-it">
                                            <img src={gp30} class="" alt="..." />
                                            <div class="sectionhome4-silde-main-right-img1-content text-center fw-bold">
                                                <p style={{ fontWeight: "bold" }} class=" card-title" id="sectionhome4-silde-main-right-img1-content-p">Permanent Employee</p>
                                            </div>

                                            <div class="d-flex justify-contents-center"><a h href="/recruitment" class="btn btn-primary text-center text-white">Learn More</a></div>
                                        </div>

                                        <div class="sectionhome4-silde-main-right-img2 carousel-item car-it">
                                            <img src={gp31} class="" alt="..." />
                                            <div class="sectionhome4-silde-main-right-img2-content text-center fw-bold">
                                                <p style={{ fontWeight: "bold" }} class=" card-title" id="sectionhome4-silde-main-right-img2-content-p">Employee Listing</p>
                                            </div>

                                            <div class="d-flex justify-contents-center"><a href="/recruitment" class="btn btn-primary text-white">Learn More</a></div>
                                        </div>

                                        <div class="sectionhome4-silde-main-right-img3 carousel-item car-it" id="sectionhome4-silde-main-right-3rd">
                                            <img src={gp32} class="" alt="..." />
                                            <div class="sectionhome4-silde-main-right-img3-content text-center fw-bold" >
                                                <p style={{ fontWeight: "bold" }} class=" card-title " id="sectionhome4-silde-main-right-img3-content-p">Temporary  Employee</p>
                                            </div>

                                            <div class="d-flex justify-contents-center"><a href="/recruitment" class="btn btn-primary text-center text-white">Learn More</a></div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                      
                        </div>
                    </section> */}
                    {/**---------------------------------------------------------------------------------------------------------- */}
                    <section class="sec-bg">
                        <div className="  text-white">
                            <p>Partner with us to get expert services</p>
                        </div>
                    </section >
                    <section class="sectioncr3 d-flex align-items-center position-relative bg-transparent " >
                        <div class="container  ">
                            <div className="row w-100 m-0 p-0">
                                <div className="col-lg-4 col-md-12 p-4">
                                    <div class="card h-100" id="sectioncr3-card-body" data-aos="fade-up" style={{ background: "#033056", color: "white" }} >
                                        <div class="card-body p-4" id="sectioncr3-card-body">
                                            <h4 className="fw-bold">Custom Digital Solution</h4>
                                            <p>We provide tailored digital solutions for your unique business needs.</p>
                                            <a href="/recruitment" className="btn btn-primary mt-4 ">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 p-4">
                                    <div class="card h-100" id="sectioncr3-card-body" data-aos="fade-up" style={{ background: "#033056", color: "white" }} >
                                        <div class="card-body p-4" id="sectioncr3-card-body">
                                            <h4 className="fw-bold">IT Consultation & Strategies </h4>
                                            <p>IT Consultation to optimize operation & enhance digital transformation to success.</p>
                                            <a href="/recruitment" className="btn btn-primary">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 p-4">
                                    <div class="card h-100" id="sectioncr3-card-body" data-aos="fade-up" style={{ background: "#033056", color: "white" }} >
                                        <div class="card-body p-4" id="sectioncr3-card-body">
                                            <h4 className="fw-bold">Data Driven Insights</h4>
                                            <p className="mt-5">Uncover Business opportunities through advanced data analysis.</p>
                                            <a href="/recruitment" className="btn btn-primary mt-4">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/**---------------------------------------------------------------------------------------------------------- */}

                    <div className="BIT-own-hm-sechome6-and-sec3-blg-com">
                        < section >
                            <div class=" container-fluid" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                                <div class="row align-items-center" >



                                    <div className="col-sm " style={{ color: "#033056", background: "transparent", }}>
                                        <p className="h5" id="sechome6-title1-1" data-aos="fade-up" >Frequently Asked Questions</p>
                                    </div>





                                    <div class="col-sm" style={{ minWidth: "65%" }}>


                                        <div className="d-flex py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                            <div className="" id="sechome6-que-main">
                                                <p className="" id="sechome6-que1" onClick={() => { handleClick(1) }}>
                                                    What are web developent companies?
                                                    <img className="m-2" src={ve} alt="Your Image" onClick={() => { handleClick(1) }} style={{ cursor: 'pointer' }} />
                                                </p>
                                                {showMessage1 && <p>
                                                    A web development company is a team of experts that helps you in the end-to-end creation of different type of websites. The company looks over the designs, performance and maintenance of the website.


                                                </p>}
                                            </div>
                                        </div>

                                        <div className="d-flex py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                            <div className="" id="sechome6-que-main">
                                                <p className="" id="sechome6-que1" onClick={() => { handleClick(2) }}>
                                                    Are web development company costly?
                                                    <img className="m-2" src={ve} alt="Your Image" onClick={() => { handleClick(2) }} style={{ cursor: 'pointer' }} />
                                                </p>
                                                {showMessage2 && <p>
                                                    A typical website may cost ₹50,000 to ₹1,00,000, or about $600 to $1,200. A smaller business site might range from ₹80,000 to ₹1,50,000, roughly $950 to $1,800. An e-commerce site could land somewhere between ₹1,50,000 to ₹4,00,000,  ($1,800 to $4,800).

                                                </p>}
                                            </div>
                                        </div>


                                        <div className="d-flex py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                            <div className="" id="sechome6-que-main">
                                                <p className="" id="sechome6-que1" onClick={() => { handleClick(3) }}>
                                                    What should i look for  in a website development company?
                                                    <img className="m-2" src={ve} alt="Your Image" onClick={() => { handleClick(3) }} style={{ cursor: 'pointer' }} />
                                                </p>
                                                {showMessage3 && <p>
                                                    When looking for a website development company, consider their know-how, abilities, personalization choices, cost, interactions, assistance, and endorsements.



                                                </p>}
                                            </div>
                                        </div>


                                        {/* <div className="d-flex  py-5 align-items-center" style={{ borderTop: "1px solid #033056", borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>

                                        <p class="h5">What are web developent companies ?<img class="m=2" src={ve} /></p>
                                    </div>
                                    <div className="d-flex  py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                        <p class="h5">Are web development company costly ?<img class="m=2" src={ve} /></p>
                                    </div>
                                    <div className="d-flex  py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                        <p class="h5">What should i look for  in a website development company?<img class="m=2" src={ve} /></p>
                                    </div>
                                    <div className="d-flex py-5 align-items-center" style={{ borderBottom: "1px solid #033056", fontWeight: "bold", color: "#033056" }}>
                                        <p class="h5">What should i look for  in a website development company?<img class="m=2" src={ve} /></p>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </section >
                        {/**---------------------------------------------------------------------------------------------------------- */}


                        <div>

                            {/* <section className='section3 h-100 align-items-center pt-5 pb-5' >

                            <div className='container pt-5 pb-5'>
                                <p className='heading' id="sechome8-title1" >Our Blog</p>
                                <div class="container ">


                                    <div class="row " id="section-home3-main-row" >

                                        <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0 pr-3 mt-3" data-aos="fade-up" data-aos-duration="1500">
                                            <img src="/img/sec7/img1.png" alt="" className="img-thumbnail rounded-5 border-0 bg-transparent" />

                                            <p className="mt-4">The future of Recruitment and Emerging trends and technologies companies should follow.</p>

                                            <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_activity-7142811230884323328-N7e1?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary mt-1 mb-2" >Read More</a>
                                        </div>

                                        <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0 pr-3 rounded mt-3" data-aos="fade-down" data-aos-duration="1500">
                                            <img src="/img/sec7/img2.png" alt="" className="img-thumbnail rounded border-0 bg-transparent " />

                                            <p className="mt-4 p-2" >8 key steps of minimize the risk of recruitment</p><br></br>
                                            <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_8-key-steps-to-minimize-the-risk-of-recruitment-activity-7141721079860953088-xRJs?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary" id="section3-box2-btn">Read More</a>
                                        </div>

                                        <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0  pr-3 rounded mt-3" data-aos="fade-up" data-aos-duration="1500">
                                            <img src="/img/sec7/img3.png " alt="" className="img-thumbnail rounded-5 border-0 bg-transparent" />

                                            <p className="mt-4">Navigation of challenges of recruitment in a competitive job market</p>
                                            <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_8-key-steps-to-minimize-the-risk-of-recruitment-activity-7141721079860953088-xRJs?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary mt-1 ">Read More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section> */}


                        </div>


                        {/* ----------------------------section 4--------------------------- */}

                        <div className="sectionb4 ">
                            <div className="sectionb4-container  container rounded-5" id="sectionb4-subpart">
                                <div className="sectionb4-subpart-row row   " >


                                    <p class="sectionb4-subpart-title1 font-weight-bold text-white">Contact us today and discover how we can help you to achieve your goals.</p>


                                    <div className="col-md-12 position-relative">
                                        <div class="container ">
                                            <div className="sectionb4-right-content text-start ">
                                                <div class="sectionb4-right-content-BIT-blg-inpt-tg" role="search">

                                                    <input class="form-control  border border-white sectionb4-right-content-BIT-blg-inpt" type="search"
                                                        placeholder="Email Address" aria-label="Search" />

                                                    <img src={BITown_hm_sec4_BLOG} alt="" className="sectionb4-right-content-BIT-blg-btn1" />
                                                    {/* <a type="button" href="/contact" class="btn primary   text-center text-white  rounded-3">Str</a> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>




                        </div>

                    </div>


                </>

            )}
        </div>

    )
}

export default Homes;