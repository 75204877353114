import React from 'react'
import './Blog.css'
import { useEffect } from 'react'



import BITown_hm_sec4_BLOG from "../images/BIT-BLOG input1-send-bt-img.png";


import BIt_blog_sec3_img1 from "../images/bit-blog-sec3-img1.png";
import BIt_blog_sec3_img2 from "../images/bit-blog-sec3-img2.png";
import BIt_blog_sec3_img3 from "../images/bit-blog-sec3-img3.png";

import BIt_blog_sec2_img1 from "../images/bit-blog-sec2-img1.png";


import bit_prd_hr_line from '../images/bit-product-hr-line-img.png';
function Blog() {


    useEffect(() => {
        document.title = 'blog';


    }, []);




    return (
        <div>

            {/* ------------------------section1----------------------- */}



            <section className='bit-blog-sec1-main'>


                <div className='bit-blog-sec1-main-title-div'>

                    <p className='bit-blog-sec1-main-p1'>OUR</p>
                    <p className='bit-blog-sec1-main-p2'>BLOGS</p>
                </div>

                <div className='bit-blog-sec1-main-hr-img-main'>
                    <img src={bit_prd_hr_line} alt="" className='bit-blog-sec1-main-hr-img' />
                </div>
                <div className='bit-blog-sec1-main-btn1-main'>
                    <a href='' className='bit-blog-sec1-main-btn1'>View all products</a>

                </div>



                {/* <div className='sec1-sub1 '>
                    <div className='secblog1-part1'>
                        <span className='heading'>OUR BLOGS</span>


                    </div>
                
                </div> */}
            </section>

            {/* ---------------section 2----------------------------- */}


            <section className='bit-blog-sec2-main'>


                <p className='bit-blog-sec2-main-title1'>Latest Trending Blogs</p>


                <div className='row m-0 p-0 w-100 bit-blog-sec2-main-row'>
                    <div className='col-lg-6 col-md-12 bit-blog-sec2-main-col1'>

                        <div>                        <p className='bit-blog-sec2-main-col1-p1'>Trends in IT Services for 2024</p>
                            <p className='bit-blog-sec2-main-col1-p2'>"Staying updated with the latest IT trends is crucial for maintaining a competitive edge. This section will highlight emerging trends in IT services for 2024, such as advancements in artificial intelligence, automation, and hybrid work solutions, to keep you informed about the future of technology."</p>
                            <div className='bit-blog-sec2-main-col1-a1-main'>

                                <a className='bit-blog-sec2-main-col1-a1'>Read more</a>
                            </div>
                        </div>

                    </div>

                    <div className='col-lg-6 col-md-12'>
                        <img src={BIt_blog_sec2_img1} alt="" className="img-thumbnail rounded-5 border-0 bg-transparent" />

                    </div>
                </div>

            </section>



















            {/* <section className='section2   '>
                <div className='secbg2-sub'>
                    <div className=''>
                        <p className='secblog2-heading1 '>What is a Recruitment?</p>
                    </div>

                    <div className='secblog2-p text-start '>
                        <p>An organization uses a set of procedures known as a recruitment process to discover, attract, and hire new personnel. Finding open positions, analyzing job requirements, writing job descriptions, collecting and evaluating applications, shortlisting applicants, holding interviews, and employing the chosen applicants are the usual procedures.</p>


                        <p className='mt-5 pt-3 pb-5 border-bottom border-dark'>
                            A well-designed hiring procedure can lower employee attrition and guarantee that only suitably qualified applicants join the company. Outsourcing Services helps in reducing costs, raising output, and improving quality.
                            Passive job seekers make up 73% of candidates. Every business job offer draws in 250 resumes on average.</p>
                    </div>
                </div>

            </section> */}

            {/* --------------------section 3--------------------------- */}


            <div>


                <section className='section3 h-100 align-items-center pt-5 pb-5' >

                    <div className='container pt-5 pb-5'>
                        {/* <p className='heading' id="sechome8-title1" >Our Blog</p> */}
                        <div class="container ">



                            <div class="row " id="section-home3-main-row" >

                                <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0 pr-3 mt-3" data-aos="fade-up">
                                    <img src={BIt_blog_sec3_img1} alt="" className="img-thumbnail rounded-5 border-0 bg-transparent" />

                                    <p className="mt-4 bit-blog-sec3-crd1-p1">The future of recruitment -Emerging trends and technologies companies should follow.</p>

                                    <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_activity-7142811230884323328-N7e1?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary mt-1 mb-2" >Read More</a>
                                </div>

                                <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0 pr-3 rounded mt-3" data-aos="fade-down">
                                    <img src={BIt_blog_sec3_img2} alt="" className="img-thumbnail rounded border-0 bg-transparent " />

                                    <p className="mt-4 p-2 bit-blog-sec3-crd1-p1" >8 key steps of minimize the risk of recruitment</p><br></br>
                                    <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_8-key-steps-to-minimize-the-risk-of-recruitment-activity-7141721079860953088-xRJs?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary" id="section3-box2-btn">Read More</a>
                                </div>

                                <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0  pr-3 rounded mt-3" data-aos="fade-up" >
                                    <img src={BIt_blog_sec3_img3} alt="" className="img-thumbnail rounded-5 border-0 bg-transparent" />

                                    <p className="mt-4 mb-0 bit-blog-sec3-crd1-p1">Navigation of challenges of recruitment in a competitive job market</p>
                                    <a href="https://www.linkedin.com/posts/brightlink-info-technologies-pvt-ltd_8-key-steps-to-minimize-the-risk-of-recruitment-activity-7141721079860953088-xRJs?utm_source=share&utm_medium=member_desktop" target="_blank" class="btn btn-outline-primary mt-5 ">Read More</a>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>




            </div>


            {/* ----------------------------section 4--------------------------- */}

            <div className="sectionb4 ">
                <div className="sectionb4-container  container rounded-5" id="sectionb4-subpart">
                    <div className="sectionb4-subpart-row row   " >


                        <p class="sectionb4-subpart-title1 font-weight-bold text-white">Your IT Partner and TalentGateway in One Place</p>


                        <div className="col-md-12 position-relative">
                            <div class="container ">
                                <div className="sectionb4-right-content text-start ">
                                    <div class="sectionb4-right-content-BIT-blg-inpt-tg" role="search">

                                        <input class="form-control  border border-white sectionb4-right-content-BIT-blg-inpt" type="search"
                                            placeholder="Email Address" aria-label="Search" />

                                        <img src={BITown_hm_sec4_BLOG} alt="" className="sectionb4-right-content-BIT-blg-btn1" />
                                        {/* <a type="button" href="/contact" class="btn primary   text-center text-white  rounded-3">Str</a> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




            </div>







        </div>
    )
}

export default Blog
