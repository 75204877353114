import React from 'react'
import './css/Header.css'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Preloader from './loader/Preloader';
import { HashLoader } from 'react-spinners';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


import bit_header_fb from "./images/facebook-1.png";
import bit_header_insta from "./images/instagram-1.png";
import bit_header_link from "./images/linkedin-1.png";

import bit_header_white_logo from "./images/bit-white-logo1.svg";
import bit_header_black_logo from "./images/bit-twitter-black.png";


function Header() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);



    // Toggle sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
        document.body.style.overflowY = isSidebarOpen ? 'auto' : 'hidden';
    };

    // Hide sidebar
    const hideSidebar = () => {
        setSidebarOpen(false);
        document.body.style.overflowY = 'auto';
    };

    // Handle loading state
    useEffect(() => {
        document.documentElement.style.overflow = 'hidden';
        const timer = setTimeout(() => {
            setLoading(false);
            document.documentElement.style.overflow = 'visible';
        }, 2000);

        // Cleanup timer on unmount
        return () => clearTimeout(timer);
    }, []);

    // Initialize AOS
    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration as needed
        });
    }, [loading]); // Reinitialize AOS when loading state changes

    // Handle link clicks to show the preloader and hide the sidebar
    const handleLinkClick = () => {
        hideSidebar(); // Close the sidebar
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };


    // // Toggle sidebar
    // const toggleSidebar = () => {
    //     setSidebarOpen(!isSidebarOpen);
    //     document.body.style.overflowY = isSidebarOpen ? 'auto' : 'hidden';
    // };

    // // Hide sidebar
    // const hideSidebar = () => {
    //     setSidebarOpen(false);
    //     document.body.style.overflowY = 'auto';
    // };

    // // Handle loading state
    // useEffect(() => {
    //     // Simulate loading delay
    //     document.documentElement.style.overflow = 'hidden';
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //         document.documentElement.style.overflow = 'visible';
    //     }, 2000);

    //     // Cleanup timer on unmount
    //     return () => clearTimeout(timer);
    // }, []);

    // // Handle link clicks to show the preloader and hide the sidebar
    // const handleLinkClick = () => {
    //     hideSidebar(); // Close the sidebar
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // };



    return (


        <div className="header">
            {loading && <Preloader />}
            <nav className="navbar navbar-expand-lg">
                <Link to="/home" onClick={handleLinkClick}>
                    <div className="d-flex">
                        {/* <img src="/images/logo.png" alt="Logo" /> */}
                        <img src={bit_header_white_logo} alt="Logo" className='bit-header-wh-logo' />
                        
                    </div>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleSidebar}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isSidebarOpen}
                    aria-label="Toggle navigation"
                >
                    <i className="fa fa-bars" style={{ color: "#FFF" }}></i>
                </button>

                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <div className="inner-div-sidebar">


                        <img src="/images/logo.png" alt="Logo" id="navbar-logo-black" />

                        {/* <img src="/images/rai-logo-black.png" alt="Logo" id="navbar-logo-black" /> */}

                        <p onClick={hideSidebar} className='bit-header-navbar-slider-right-p'>
                            <i className="fa-solid fa-xmark" style={{ fontSize: 'larger', color: 'black' }}></i>
                        </p>
                    </div>
                    <ul>
                        <li><Link to="/home" onClick={handleLinkClick}>Home</Link></li>
                        <li><Link to="/about" onClick={handleLinkClick}>About Us</Link></li>
                        <li><Link to="/itservices" onClick={handleLinkClick}>IT Services</Link></li>
                        <li><Link to="/recruitment" onClick={handleLinkClick}>Recruitment</Link></li>
                        <li><Link to="/product" onClick={handleLinkClick}>Product</Link></li>
                        <li><Link to="/blog" onClick={handleLinkClick}>Blog</Link></li>
                        <li><Link to="/career" onClick={handleLinkClick}>Career Page</Link></li>
                        <li><Link to="/contact" onClick={handleLinkClick}>Contact Us</Link></li>

                            
                        {/* bit_header_fb */}

                        <li>
                            <div className='bit-header-social-link-main-div'>
                                <a href='https://www.linkedin.com/company/brightlink-info-technologies-pvt-ltd/' className='bit-header-social-link-main-div-a' ><img src={bit_header_link} alt="Logo" className='bit-header-social-link' /></a>

                                <a href='https://www.facebook.com/p/BrightLink-Info-Technologies-Pvt-Ltd-61554362690141/?paipv=0&eav=AfarGdNKxLmQWP4uW5vMQjoltNJnAXEi_60xqGFigaotAiCsFvE0Tqu-YZzsmBA8Q1k&_rdr' className='bit-header-social-link-main-div-a'>
                                    <img src={bit_header_fb} alt="Logo" className='bit-header-social-link' />
                                </a>

                                <a href='https://www.instagram.com/brightlink_info_technologies/' className='bit-header-social-link-main-div-a' >
                                    <img src={bit_header_insta} alt="Logo" className='bit-header-social-link' />
                                </a>

                                <a href='https://x.com/BrightLinkInfo/following' className='bit-header-social-link-main-div-a' >
                                    <img src={bit_header_black_logo} alt="Logo" className='bit-header-social-link' />
                                </a>
                            </div>
                        </li>
                        <li className="navbar-copywright">
                            <p className='bit-slidebar-privacy-plc-p1'>@2024 BrightLink Info Technologies Private Limited. All rights reserved</p>
                        </li>
                    </ul>
                </div>





                <div className={`collapse navbar-collapse ${isSidebarOpen ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <Link to="/home" onClick={handleLinkClick} className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" onClick={handleLinkClick} className="nav-link">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/itservices" onClick={handleLinkClick} className="nav-link">IT Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/recruitment" onClick={handleLinkClick} className="nav-link">Recruitment</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/product" onClick={handleLinkClick} className="nav-link">Product</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/blog" onClick={handleLinkClick} className="nav-link">Blog</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/career" onClick={handleLinkClick} className="nav-link">Career Page</Link>
                        </li>
                    </ul>
                    <Link to="/contact">
                        <button className="btn btn-outline-light my-2 my-sm-0 contactbtn" type="submit" onClick={handleLinkClick}>Contact us</button>
                    </Link>
                </div>
            </nav>
        </div>
    )
}

export default Header





