import "./recruitmentt.css"
import image1 from "./img/Rectangle114.png"
import image2 from "./img/Rectangle119.png"
import image3 from "./img/se.png"
import image4 from "./img/Group120.png"
import { useEffect } from "react"

import bit_recruit_sec2_lft_img from "../recruitment/img/bit-recruitment-sec2-img1.png";

import bit_recruit_sec3_crd_img1 from "../recruitment/bit-recruitmnet-sec3-main-row1-img1.png";
import bit_recruit_sec3_crd_img2 from "../recruitment/bit-recruitmnet-sec3-main-row1-img2.png";
import bit_recruit_sec3_crd_img3 from "../recruitment/bit-recruitmnet-sec3-main-row1-img3.png";
import bit_recruit_sec3_crd_img4 from "../recruitment/bit-recruitmnet-sec3-main-row1-img4.png";
import bit_recruit_sec3_crd_img5 from "../recruitment/bit-recruitmnet-sec3-main-row1-img5.png";
import bit_recruit_sec3_crd_img6 from "../recruitment/bit-recruitmnet-sec3-main-row1-img6.png";

import bit_recruit_sec4_right_img1 from "../recruitment/bit-recruitment-sec4-right-img.png";

function Recruitment() {
    useEffect(() => {
        document.title = 'Recruitment';


    }, []);
    return (
        <>

            {/**--------------------------------------Section 1-------------------------------- */}
            <section class="sectorr1">

                <div class="sectorr1-main ">

                    {/* <p class="l1" id="l1">recruitment support services</p> */}


                    <p class="gradient">"Empowering Organizations to Align Exceptional Talent with Outstanding Opportunities"</p>


                    <p class="l2">Are you looking for job ? Then BrightLink<br /> Info Technologies Pvt Ltd. is the<br /> solution</p>

                    <div class="text-start">
                        <a href="/career" class="btn btn-button">Find job now</a>
                    </div>
                </div>

            </section>
            <section class=" bit-recruit-sec2-main">

                <div className="row m-0 p-0 w-100">

                    <div className="col-md-5">
                        <img className="bit-recruit-sec2-main-row1-img1" src={bit_recruit_sec2_lft_img}  data-aos="zoom-in"/>
                    </div>

                    <div className="col-md-7 bit-recruit-sec2-main-row1-col2">

                        <div className="bit-recruit-sec2-main-row1-col2-sub-div"  data-aos="fade-up" >

                            <p className="bit-recruit-sec2-main-row1-col2-p1" >We are a recruitment company providing you with varieties of jobs and desired packages to desired companies to be a part of us in this journey at The Bright Info Technologies.</p>

                        </div>
                    </div>

                </div>


                {/* <div className="boxx">
                        
                        <div className="box2 d-flex align-items-center">
                            <p class="l11 ">We are a recruitment company providing you with varieties of jobs and desired packages to desired companies to be a part of us in this journey at The BrightLink Info Technologies.</p>
                        </div>
                        <div className="box1 d-flex">
                            <img class="img1 img-fluid imgbox" src={image1} />
                        </div>
                    </div> */}

            </section>
            {/*  <section className="h-100">
                <div className="row ">
                    <div className="col position-absolute top-50 start-50 translate-middle">
                        <div className="">
                            <img class="img1 img-fluid" src={image1} />
                        </div>
                    </div>
                    <div className="col position-absolute bottom-50 end-50 translate-middle">
                        <div className="  d-flex justify-content-end align-item-center ">
                            <p class="l11 h3">We are a recruitment company providing you with varieties of jobs and desired packages to desired companies to be a part of us in this journey at The Bright Info Technologies.</p>
                        </div>
                    </div>
                </div>
            </section> */}



            {/**--------------------------------------Section 2-------------------------------- */}
            {/* <section className="sector2">
                <div className="sector2-main-box">
                    <div className="box1">
                        <p className="l1">Our Comprehensive staffing services encompass:</p>
                        <p className="l2">- The power of  recruitment</p>
                        <p className="l2">- Non-technical</p>
                        <p className="l2">- Offshore staffing solutions</p>
                        <p className="l2">- Technical and industrial staffing</p>
                        <p className="l2">- Recruitment process outsourcing</p>
                        <p className="l2">- Accounting, financial and managerial staffing</p>

                    </div>

                    <div className="box2 d-flex align-items-center">
                        <img src={image2} class="img-fluid img " data-aos="fade-left"></img>
                    </div>
                </div>
            </section> */}



            <section className="bit-recruitmnet-sec3-main">

                <div className="container">
                    <p className="bit-recruitmnet-sec3-main-title1 bit-recruitment-hover-zoom">Our Comprehensive Staffing Services Encompass
                    </p>




                    <div className="row m-0 p-0 w-100 bit-recruitmnet-sec3-main-row1">

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom" data-aos="zoom-in" > 
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">
                                    <img src={bit_recruit_sec3_crd_img1} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Power Of Recruitment</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom"  data-aos="zoom-in" >
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">

                                    <img src={bit_recruit_sec3_crd_img2} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Non Technical </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom"  data-aos="zoom-in" >
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">
                                    <img src={bit_recruit_sec3_crd_img3} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Offshore staffing Solution</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom"  data-aos="zoom-in" >
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">
                                    <img src={bit_recruit_sec3_crd_img4} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Technical And Industrial Staffing</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom"  data-aos="zoom-in" >
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">
                                    <img src={bit_recruit_sec3_crd_img5} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Recruitment Process Outsourcing</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 bit-recruitmnet-sec3-main-row1-col1 bit-recruitment-hover-zoom"  data-aos="zoom-in" >
                            <div className="card bit-recruitmnet-sec3-main-row1-col1-card">
                                <div className="card-body bit-recruitmnet-sec3-main-row1-col1-card-body">
                                    <img src={bit_recruit_sec3_crd_img6} className="bit-recruitmnet-sec3-main-row1-col1-card-img1" />
                                    <p className="bit-recruitmnet-sec3-main-row1-col1-card-P1 bit-recruitment-hover-zoom">Accounting, Financial & Managerial staffing </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>




            {/**--------------------------------------Section 3-------------------------------- */}
            <section className="sectorr3 bit-recruitment-sec4-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">

                            <p className="l1 h4">What do we offer?</p>
                            <p className="l2">Unleash the Power of Recruitment and Staffing Solutions!</p>
                            <ul className="bit-recruitment-sec4-row-ul">
                                <li>
                                    <p className="l3">RPO - Recruitment Process Outsourcing</p>
                                    <p className="l4 h6">RPO encompasses various interpretations. It can mean managing specific activities or processes. Some consider it as assuming full ownership of a client's entire recruitment function. Others view it as deploying recruiters on-site at the client's location.</p>

                                </li>
                            </ul>


                            <ul className="bit-recruitment-sec4-row-ul">
                  
                                <li>


                                    <p className="l3 ">Permanent Staffing</p>
                                    <p className="l4 h6">We assist in the critical process of hiring permanent employees, ensuring you select the right individuals to integrate into your organization.</p>
                                </li>
                            </ul>



                            <ul className="bit-recruitment-sec4-row-ul">
                         
                                <li>
                                    <p className="l3 ">Employee Leasing</p>
                                    <p className="l4 h6">If you seek to recruit talent without the complexities of HR management, our employee leasing solutions offer a swift and hassle-free way to bring new hires onboard.</p>

                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-12 sectorr3-right-img col-sm bit-recruitment-sec4-main-row-col2">
                            <img src={bit_recruit_sec4_right_img1} className="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="sectorr4">
                <div className="sectorr41">
                    <p className="l1 mt-5 bit-recruitment-hover-zoom">Our Proven Hiring Process:</p>
                    <div className="container mt-5">
                        <div className="row d-flex justify-content-center">
                           
                            <div className="col-4 p-0" >
                                <div className="ca" id="sectorr4-box1">
                                    <p className="ll1 bit-recruitment-hover-zoom">Identifying Candidates:</p>
                                    <p className="ll2">We source qualified professionals from diverse channels, building a robust candidate pool through job boards, referrals and networking.</p>
                                </div>
                                <div className="ca" id="sectorr4-box2">
                                    <p className="ll1 bit-recruitment-hover-zoom">Shortlisting and Screening:</p>
                                    <p className="ll2">Candidates are carefully assessed based on experience, skills, and fit. In-depth evaluations, including face-to-face interviews and background checks, are conducted.</p>
                                </div>
                            </div>
                            <div className="col-2 p-0 d-flex align-items-center justify-content-center">
                                <img src={image4} className="img-fluid" />
                            </div>
                            <div className="col-4 p-0">
                                <div className="ca " id="sectorr4-box3">
                                    <p className="ll1 text-left bit-recruitment-hover-zoom">Interview Process </p>
                                    <p className="ll2 text-left">After the phone/initial interviews are completed, we will shortlist people for in-person interviews, and our team will interview each of the final candidates.
                                    </p>
                                </div>
                                <div className="ca " id="sectorr4-box4">
                                    <p className="ll1 text-left bit-recruitment-hover-zoom">Placement and Ongoing Support:</p>
                                    <p className="ll2 text-left" >Unlike others, our commitment doesn't end with placement. We continuously monitor employee performance, ensuring top-quality service.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default Recruitment;