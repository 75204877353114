import React from "react";
import './body.css';


const Body=()=>{
    return(
    <div className="body-div">

    </div>        
    )
    
}

export default Body;